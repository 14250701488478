.work-with-us-section{
    background-color: rgb(58, 42, 209);
    color: white;
    padding: 100px 20px;
}
.work-with-us-div{
    width: 70%;
    margin:  auto;
    text-align: center;
}
.work-with-us-h1{
    font-size: 45px;
    font-weight: 900;
}
.work-with-us-p{
    font-size: 25px;
}
@media only screen and (min-width: 220px) and (max-width: 989px){
    .work-with-us-section{
        padding: 50px 20px;
    }
    .work-with-us-div{
        width: 100%;
    }
    .work-with-us-h1{
        font-weight: 900;
    }
    .work-with-us-p {
        font-size: 15px;
    }
 
  }